@import "@/styles/_variables.scss";
















































































































a.uk-card-body {
  text-decoration: none;
}
div.space-between {
  justify-content: space-between;
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(+7%);
  transform: translateY(+7%);
}
.hover-no-text-decoration:hover {
    text-decoration: none;
}
